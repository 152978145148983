export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
     // testimonials (reviews)
     jQuery(function($) {
      if( $('.testimonials-slider').length ) {
        $('.testimonials-slider').slick({
          dots: true,
          dotsClass: 'slick-dots',
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          adaptiveHeight: true,
          prevArrow: false,
          nextArrow: false,
      });
      }
    });
  },
};
