// import external dependencies
import 'jquery';
import Alpine from 'alpinejs';
import Bugsnag from '@bugsnag/js';

// import everything from autoload


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import singleDestination from './routes/singleDestination';
import blog from './blog';

// Start BugSnag.
window.Bugsnag = Bugsnag;
Bugsnag.start({
  apiKey: '71acf0efa72ba0075d8a96e26170256c',
  releaseStage:
    window.location.toString().includes('locallens.com') ? 'production' : (
      window.location.toString().includes('locallensstage.wpengine.com') ? 'staging' : (
        window.location.toString().includes('locallensdev.wpengine.com') ? 'development' : 'localhost'
      )
    ),
});

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  // destination pages
  singleDestination,
  // main blog page
  blog,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());

// Initialize AlpineJS.
window.Alpine = Alpine;
Alpine.start();
