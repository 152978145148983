export default {
  init() {
    // eslint-disable-next-line no-undef
    console.log(ajax_object);
  },
  finalize() {
jQuery(function($) {
  console.log('blog page ready');
  // var count = 0;
  var firstLoad = true;
  // var loc_clicked = false;
  // var research_clicked = false;
  // var role_clicked = false;
  // var dd_clicked = false;
  var selected_cat = 0;
  var selected_city = '';
  // var selected_role = 0;
  // var curr_main_cat = 'faculty'; //can also be 'phd'
  var page = 1; // What page we are on.
  var ppp = 12; // Post per page
  var curr_offset = 0;
  var canBeLoaded = true; // this param allows to initiate the AJAX call only if necessary
  // var bottomOffset = 2400; // the distance (in px) from the page bottom when you want to load more posts
  var loadMoreOffset = '';
  var loadMoreOffsetTop = '';
  // // query vars
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  console.log('ajax_object');
  // eslint-disable-next-line no-undef
  console.log(ajax_object);

  function getResults(selected_cat,selected_city) {
      console.log('getResults');
      // var page = 1; // What page we are on.

      $.ajax({
          // eslint-disable-next-line no-undef
          url: ajax_object.ajax_url,
          type: 'POST',
          dataType: 'json',
          data: {
            chosen_cat: selected_cat,
            chosen_city: selected_city,
            offset: curr_offset,
            // eslint-disable-next-line no-undef
            _wpnonce : ajax_object.ajax_nonce,
            action : 'blog_filter_ajax',
          },
          beforeSend: function() {
            console.log('before send');
              canBeLoaded = false;
            },
      }).success(function(posts){
          console.log('success!!');
          canBeLoaded = true;
          $('.blog-load-more-btn').text('');
          displayResults(posts);

      }).error(function(err){
          console.log('error!!'+err);
          console.log(err);
      });

    }

    $('.blog-load-more-btn').text('Loading...');
    checkURLparams();

    function displayResults(posts) {
      console.log(posts);

      if( ( posts.length === 0 ) && $('.blog-posts').children().length > 0 ) {
        canBeLoaded = false;
        $('.blog-load-more-btn').text('');
      }
      if( ( posts.length === 0 ) && $('.blog-posts').children().length === 0 ) {
        canBeLoaded = false;
        $('.blog-load-more-btn').text('No results found.');
      }

      var blogResults = '';

      $.each( posts, function( key, value ) {
        console.log( 'title:'+value['title'] );

        blogResults+='<div class="col-span-1 fade-in-div">';
        blogResults+='<article class="pb-6 md:pr-6">';
        blogResults+='<div class="post-thumb max-h-64 overflow-hidden">';
        blogResults+='<img src="'+value['thumb']+'" />';
        blogResults+='</div>';
        blogResults+='<div class="blog-single-bottom p-6" style="background-color: rgb(246 246 246);">';
        blogResults+='<h4 class="entry-title font-semibold text-lg leading-6"><a href="'+value['link']+'">"'+value['title']+'"</a></h4>';
        blogResults+='<a href="'+value['link']+'" target="_blank" class="text-center cta p-2 w-full md:w-1/2 lg:w-1/3"><button>Read more</button></a>';
        blogResults+='</div>';
        blogResults+='</article>';
        blogResults+='</div>';

      });

      if(firstLoad) {
        $('.blog-posts').html('');
        $('.blog-posts').append(blogResults);
        firstLoad = false;
        loadMoreOffset = $('.blog-load-more-btn').offset();
        console.log('loadMoreOffset= '+loadMoreOffset);
        loadMoreOffsetTop = Math.round(loadMoreOffset.top);
        console.log('loadMoreOffsetTop= '+loadMoreOffsetTop);
      } else {
        $('.blog-posts').append(blogResults);
      }

    }

    // lazy loading more posts
    $(window).on( 'scroll', function() {
      console.log('scroll');
      console.log('$(document).scrollTop()= '+$(document).scrollTop());
      console.log('$(document).height()= '+$(document).height());
      console.log('loadMoreOffsetTop= '+loadMoreOffsetTop);
      console.log('canBeLoaded= '+canBeLoaded);
        
      if( $(document).scrollTop() > ( $(document).height() - loadMoreOffsetTop  ) && canBeLoaded == true ) {
        console.log('scrollit!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
        curr_offset = (page * ppp);
        page++;
        getResults(selected_cat,selected_city)
        $('.blog-load-more-btn').text('Loading...');
      }
    });

    // category dropdown
    $('#blog-filter-dropdown-categories').on( 'change', function() {
      // dd_clicked = true;
      // jQuery
      var selectedVal = $(this).find(':selected').val();
      console.log('selectedVal= '+ selectedVal);
      selected_cat = selectedVal;
      resetResults();
    });

    // city dropdown
    $('#blog-filter-dropdown-cities').on( 'change', function() {
      // dd_clicked = true;
      // jQuery
      var selectedVal = $(this).find(':selected').val();
      console.log('selectedVal= '+ selectedVal);
      selected_city = selectedVal;
      resetResults();
    });

    // reset results
    function resetResults() {
      page = 1;
      curr_offset = 0;
      $('.blog-posts').html('');
      $('.blog-load-more-btn').text('Loading...');
      getResults(selected_cat,selected_city)
    }

    // check url params
    function checkURLparams() {
      // Check for url params on page load
      if( urlParams.has('city') ) {
        console.log('city param found');
        var cityParam = urlParams.get('city');
        console.log('cityParam= '+cityParam);
        var cityTag = '';
        var hasName = cityParam.includes('in-');
        console.log( 'hasName= '+hasName );

        // split tag to remove "name in..."
        if( cityParam.includes('in-') ) {
          console.log('includes in-');
          var cityArray = cityParam.split('in-');
          var photographerName = cityArray[0];
          var cityName = cityArray[1];
          console.log('photographerName = '+photographerName );
          console.log('cityName= '+cityName);
          cityTag = cityName;
        } else {
          console.log('does not include in-');
          cityTag = cityParam;
        }
        
        $('#blog-filter-dropdown-cities option[value="'+cityTag+'"]').attr('selected', true)
        selected_city = cityTag;
      }
      if( urlParams.has('cat') ) {
        console.log('cat param found');
        const catParam = urlParams.get('cat');
        console.log('cityParam= '+catParam);
        $('#blog-filter-dropdown-categories option[value="'+catParam+'"]').attr('selected', true)
        selected_cat = catParam;
      }
      getResults(selected_cat,selected_city);
    }

});
},
};