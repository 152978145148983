import MicroModal from 'micromodal';
MicroModal.init();

export default {
  init() {
    // JavaScript to be fired on all pages\
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    console.log('destination page ready');

    // trigger wonder gallery when See more Photos button is clicked
    $('#see-more-photos').on( 'click', function(evt) {
      evt.preventDefault();
      $('.wpgridlightbox').first().trigger('click');
    });

    // photo spots
    jQuery(function($) {
      $('.sposts').slick({
          centerMode: false,
          centerPadding: '60px',
          slidesToShow: 4,
          // Mobile
          responsive: [{
              breakpoint: 768,
              settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '40px',
                  slidesToShow: 1,
              },
          }],
      });
    });

    // photo spots See More links
    jQuery(function($) {
      var id = $('.photo-spots').attr('id');
      if( id ) {
      var see_more_link = id.replace('photo-spots-','');
      console.log(see_more_link);

      if( see_more_link !== '' ) {
        $('.photo-spot-see-more-link').on( 'click', function(evt) {
          evt.preventDefault();
          // go to blog posts under city category
          window.open('/blog/?city='+see_more_link, '_blank');
        });
      } else {
        $('.photo-spot-see-more-link').hide();
      }
    }

    });

    // blog slider
    jQuery(function($) {
      $('.blog-slider').slick({
          centerMode: false,
          centerPadding: '60px',
          slidesToShow: 3,
          // Mobile
          responsive: [{
              breakpoint: 768,
              settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '40px',
                  slidesToShow: 1,
              },
          } ],
      });
    });

    // Micromodal stuff
    jQuery(function($) {
      $('.photo-spot-read-more-link').on( 'click', function(evt) {
        evt.preventDefault();
        var modal_id = $(this).attr('id');
        var modalName = 'modal-'+modal_id;
        MicroModal.show( modalName );
      });
    });

    /**
     * Events rollover functionality.
     */
    jQuery(function($) {
      $(document).ready(function() {

        // Get the event count.
        const numEvents = $('#ll-events-rollover').data('num-events');

        // Track items.
        let itemPrev = -1;
        let itemNext = 0;

        // Track tween state.
        let tween = 0;

        // Local function to rotate events.
        function rotateEvents() {

          // Check current tween.
          if(tween < 1) {

            // Update opacity.
            $(`#ll-events-rollover-${itemPrev}`).css('opacity', 1 - tween);
            $(`#ll-events-rollover-${itemNext}`).css('opacity', tween);

            // Repeat until done.
            setTimeout(rotateEvents, 33);

          } else {

            // Finalize opacity (reset all to prevent visual glitch that occurs when switching tabs).
            for(let item = 0; item < numEvents; item++) {
              $(`#ll-events-rollover-${item}`).css('opacity', item === itemNext ? 1 : 0);
            }
          }

          // Update tween value.
          tween += 0.1;
        }

        // Update items.
        setInterval(function() {

          // Rotate items.
          itemPrev = itemNext;
          itemNext = (itemPrev + 1) % numEvents;

          // Reset tween value.
          tween = 0;

          // Rotate the events.
          setTimeout(rotateEvents, 33);

        }, 2200);
      });
    });

  },
};


